import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from  './router'
import request from './util/request'
import Storage from  'vue-ls'

Vue.config.productionTip = false;
Vue.prototype.$http = request;

const options = {
  namespace: 'S_',
  name: 'ls',
  storage: 'local'
};

Vue.use(Storage, options);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
