import Vue from 'vue'
import axios from 'axios'
import router from '@/router'

const http = axios.create({
    // baseURL: 'http://localhost:9074/college/',
    //baseURL: 'http://139.155.69.147:9074/college/',
    // baseURL:'http://dfxy.yunyuzh.com/college/',
    baseURL:'/college/', // prod
    timeout: 1000 * 60,
    headers: {
        'Content-Type': 'application/json'
    }
});

http.interceptors.request.use(config => {
    if (Vue.ls.get('USER')){
        config.headers[ 'X-Access-Token' ]  = Vue.ls.get('USER').token;
    }
    return config;
}, (error => {
    return Promise.reject(error);
}));


http.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response) {
        let data = error.response.data;
        console.log(error.response.data);
        let token;
        if (Vue.ls.get('USER')){
            token = Vue.ls.get('USER').token;
        }
        switch (error.response.status) {
            case 403:
                // notification.error({message: '系统提示', description: '拒绝访问', duration: 4})
                break;
            case 500:
                if (!token) {
                    /*                    Modal.error({
                                            title: '登陆已过期',
                                            content: '无token，请重新登陆',
                                            okText: '重新登陆',
                                            mask: false,
                                            onOk: () => {
                                                Vue.ls.remove('TOKEN');
                                                router.push({path: '/'})
                                            }
                                        })*/
                    console.log('...............token无效')
                    Vue.ls.remove('USER');
                    router.push({path: '/'})
                }
                if (token && data.message == 'Token失效,请重新登录'){
                    /*                    Modal.error({
                                            title: '登陆已过期',
                                            content: '很抱歉，登陆已过期，请重新登陆',
                                            okText: '重新登陆',
                                            mask: false,
                                            onOk: () => {
                                                Vue.ls.remove('TOKEN');
                                                router.push({path: '/'})
                                            }
                                        })*/
                    Vue.ls.remove('USER');
                    router.push({path: '/'})
                }
                break;
            case 404:
                // notification.error({message: '系统提示', description: '很抱歉，资源未找到', duration: 4});
                break;
            case 504:
                // notification.error({message: '系统提示', description: '网络超时'});
                break;
            case 401:
                // notification.error({message: '系统提示', description: '未授权，请重新登陆', duration: 4});
                if (token) {
                    router.push({path: '/'})
                }
                break;
            default:
                /*                notification.error({
                                    message: '系统提示',
                                    description: data.message,
                                    duration: 4
                                });*/
                break;
        }
    }
    return Promise.reject(error);
});

export default http;
