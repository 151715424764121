import Vue from 'vue'
import VueRouter from  'vue-router'

Vue.use(VueRouter);

export const routerMap = [
    {path: '/', component: () => import('@/view/login')},
    {path: '/collect', component: () => import('@/view/collect')},
    {path: '/collect2', component: () => import('@/view/phoneCollect')},
    {path: '/main', component: () => import('@/view/main')},
    {path: '/resume', component: () => import('@/view/uploadJobModal')},
];

const router = new VueRouter({
    scrollBehavior: () => ({ y: 0}),
    routes: routerMap
});

export default  router;
